import React from "react";

const Header: React.FC = () => {
  return (
    <header className="bg-white text-brand-red p-4 shadow-md">
      <div className="max-w-full mx-4 sm:mx-6 lg:mx-8">
        <a href="https://awara-it.com">
          <img
            src="/images/awarait.svg"
            alt="Awara IT Logo"
            className="h-8" // Adjust the height as needed
          />
        </a>
      </div>
    </header>
  );
};

export default Header;
