import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Description from "./components/Description";
import CTA from "./components/CTA";
import Footer from "./components/Footer";

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Features />
      <Description />
      <CTA />
      <Footer />
    </div>
  );
};

export default App;
