import React, { useState } from "react";
import ContactModal from "./ContactModal";

const CTA: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="py-20 bg-gradient-to-r from-brand-red to-brand-purple text-white text-center">
      <div className="container mx-auto px-4">
        <h3 className="text-3xl font-bold mb-4">
          Хотите отслеживать утечки данных?
        </h3>
        <p className="mb-8 text-xl">
          Свяжитесь с нами прямо сейчас и вовремя узнайте о факте утечки и
          предпримите меры
        </p>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-white text-brand-red px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300"
        >
          Связаться с нами
        </button>
      </div>
      <ContactModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </section>
  );
};

export default CTA;
