import React, { useState } from "react";
import ContactModal from "./ContactModal";

const Hero: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section
      className="bg-cover bg-center h-screen flex items-center justify-center text-white"
      style={{
        backgroundImage:
          "url('https://nn7jpydcdtt0z3oh.public.blob.vercel-storage.com/awarabackfull-7mbGNm73fZ2fNuDyGC0pF6SjD1mBgM.png')",
      }}
    >
      <div className="bg-black bg-opacity-50 w-full h-full flex flex-col items-center justify-center text-center">
        <h2 className="text-6xl font-bold mb-6">Data Leak</h2>
        <p className="text-2xl mb-10">
          Автоматизированный круглосуточный сервис мониторинга утечек критически
          важной информации
        </p>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-brand-gradient text-white px-8 py-3 rounded-full text-xl hover:opacity-90 transition duration-300"
        >
          Связаться с нами
        </button>
      </div>
      <ContactModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </section>
  );
};

export default Hero;
