import React from "react";

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-10 rounded-xl max-w-lg w-full text-center shadow-2xl">
        <h2 className="text-3xl font-bold mb-6 text-brand-red">
          Свяжитесь с нами
        </h2>
        <p className="mb-8 text-gray-700 text-lg">
          Мы готовы ответить на ваши вопросы и помочь вам с услугой Data Leak.
        </p>
        <ul className="mb-8 text-gray-800 inline-block text-left space-y-4">
          <li className="flex items-center">
            <svg
              className="w-6 h-6 mr-3 text-brand-red"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span className="text-lg">
              <strong className="text-brand-dark-red">Email:</strong>{" "}
              dataleak@awara-it.com
            </span>
          </li>
          <li className="flex items-center">
            <svg
              className="w-6 h-6 mr-3 text-brand-red"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <span className="text-lg">
              <strong className="text-brand-dark-red">Телефон:</strong> +7 (727)
              321-0578
            </span>
          </li>
          <li className="flex items-center">
            <svg
              className="w-6 h-6 mr-3 text-brand-red"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span className="text-lg">
              <strong className="text-brand-dark-red">Адрес:</strong>{" "}
              050010/A25D9D1, Г. Алматы, ​Проспект Достык, 38​
            </span>
          </li>
        </ul>
        <button
          onClick={onClose}
          className="bg-brand-red text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-brand-dark-red transition duration-300 shadow-md hover:shadow-lg"
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default ContactModal;
