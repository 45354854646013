import React from "react";

const Description: React.FC = () => {
  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-brand-dark-red">
          О сервисе Data Leak
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <DescriptionCard
            title="Что такое Data Leak?"
            content="Data Leak – это сервис, предназначенный для поиска утечек и реагирования на утечки в сети интернет."
          />
          <DescriptionCard
            title="Как это работает?"
            content="Сервис подключает вас к нашим поисковым и OSINT-системам для круглогодичного мониторинга информации в сети интернет, включая открытый и закрытый сегменты (Tor)."
          />
          <DescriptionCard
            title="Что мы мониторим?"
            content="Мы мониторим информацию о доменном имени, IP-адресах, почтовых ящиках, именах пользователей, номерах телефонов и файлах, связанных с вашими активами."
          />
          <DescriptionCard
            title="Как предоставляется услуга?"
            content={
              <>
                Сервис предоставляется по подписке на 1 календарный год
                <br />
                (12 месяцев) с ежемесячным отчетом по подключенным активам.
              </>
            }
          />
        </div>
        <p className="mt-8 text-xl text-center text-gray-700">
          В случае обнаружения утечек между отчетными периодами, наши
          специалисты немедленно уведомят вас.
        </p>
      </div>
    </section>
  );
};

const DescriptionCard: React.FC<{
  title: string;
  content: React.ReactNode;
}> = ({ title, content }) => (
  <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl hover:scale-105">
    <h3 className="text-2xl font-semibold mb-4 text-brand-red">{title}</h3>
    <p className="text-lg text-gray-700">{content}</p>
  </div>
);

export default Description;
