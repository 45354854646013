import React from "react";

const Features: React.FC = () => {
  const features = [
    {
      title: "Круглогодичный мониторинг",
      description: "Непрерывное отслеживание утечек данных 24/7/365",
      icon: "🕒",
    },
    {
      title: "Поиск в открытом и закрытом сегменте сети",
      description:
        "Анализ как общедоступных, так и скрытых частей интернета, включая Tor",
      icon: "🔍",
    },
    {
      title: "Мониторинг по вашим маркерам",
      description:
        "Настраиваемый поиск по ключевым словам и идентификаторам вашей компании",
      icon: "🏷️",
    },
    {
      title: "Подключение активов (доменов)",
      description:
        "Отслеживание информации, связанной с вашими доменами и цифровыми активами",
      icon: "🌐",
    },
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-brand-dark-red">
          Особенности сервиса
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105"
            >
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-4">{feature.icon}</span>
                <h3 className="text-2xl font-semibold text-brand-red">
                  {feature.title}
                </h3>
              </div>
              <p className="text-gray-700 text-lg">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
