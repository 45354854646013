import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4 flex flex-col items-center">
        <img
          src="images/awaraitwhite.svg"
          alt="Awara IT Logo"
          className="h-12 mb-4" // Adjust the height as needed
        />
        <p>&copy; 2024 Awara IT. Все права защищены.</p>
      </div>
    </footer>
  );
};

export default Footer;
